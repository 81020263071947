import EventBus from "@/plugins/eventbus";

export default {
    install(Vue) {
        Vue.mixin({
            methods: {
                notify(message) {
                    EventBus.$emit('notify', message)
                }
            }
        });
    }
};