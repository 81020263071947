<template>
    <div>
        <v-row>
            <v-col cols="6">
                <v-select :items="anni" v-model="anno"/>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <apexchart height="400" :options="options" :series="series" type="line"></apexchart>
            </v-col>
        </v-row>
        <div class="row mt-5">
            <v-col cols="12">
                <div>
                    <v-data-table
                        :headers="months_header"
                        :items="series_month"
                        hide-default-footer
                        disable-pagination
                        dense
                    >
                    </v-data-table>
                </div>
            </v-col>
        </div>
    </div>

</template>

<script>
export default {
    data: () => ({
        series: [],
        series_month: [],
        anno: new Date().getFullYear(),
        months_header: [],
        months: ['gennaio', 'febbraio', 'marzo', 'aprile', 'maggio', 'giugno',
            'luglio', 'agosto', 'settembre', 'ottobre', 'novembre', 'dicembre']

    }),
    mounted() {
        this.init();
        this.refresh();
        this.months_header.push({text:'Convenzione', value:'label'})
        this.months.forEach(function (item) {
            let obj = {
                text: item,
                value: item,
            }
            this.months_header.push(obj)
        }, this);
    },
    computed: {
        anni() {
            const year = new Date().getFullYear();
            return [...Array(year - 2020 + 1).keys()].map(n => n + 2020);
        },
        options() {
            return {
                dataLabels: {
                    enabled: true,
                },
                xaxis: {
                    categories: ['Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu',
                        'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic']
                }
            };
        },
    },
    watch: {
        anno() {
            this.refresh();
        },
    },
    methods: {
        async init() {
            await this.refresh();
        },
        refresh: async function () {
            const params = {anno: this.anno};
            const res = await this.$http.get('/audit/statistiche/ordinativi_affidati', params);

            if (res.ok) {
                this.series = [{type:'line', data: res.result.data.totali}];
                let data = []
                res.result.data.labels.forEach((label, i) => {
                    const item = {
                        label,
                    }
                    this.months.forEach((mese, j) => {
                        item[mese] = res.result.data.values[i][j]
                    })
                    data.push(item)
                })
                const item = {label:'Totale'}
                this.months.forEach((mese, j) => {
                    item[mese] = res.result.data.totali[j]
                })
                data.push(item)
                this.series_month = data
            }
        },
    }
}

</script>