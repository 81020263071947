import axios from "axios";
import qs from "qs";
import {mapState} from "vuex";
import download from "downloadjs";
import EventBus from "@/plugins/eventbus";

class Http {
    constructor(token = null) {
        this.baseURL = window.document.location.port === '8080' ? 'http://localhost:8000/api' : '/api';
        this.client = axios.create({
            baseURL: this.baseURL,
            paramsSerializer: params => {
                return qs.stringify(params, {arrayFormat: "repeat"});
            }
        });
        if (token)
            this.client.defaults.headers.common['Authorization'] = 'token ' + token;
        this.client.interceptors.response.use(response => response, error => {
            if (error.response.status === 401) {
                EventBus.$emit('logout');
            }
            if (error.response.status === 500) {
                const html = error.response.data;
                const regex = /<div id="summary">(.*?)<\/div>/gms
                const res = html.match(regex);
                EventBus.$emit('show-error', res[0]);
            }
            return Promise.reject(error);
        });
    }

    async call(params) {
        try {
            return {
                ok: true,
                result: await this.client(params),
            }
        } catch (error) {
            return {
                ok: false,
                result: error.response.data,
            }
        }
    }

    async get(url, params) {
        return await this.call({
            method: 'get',
            url,
            ...{params}
        });
    }

    async post(url, data) {
        return await this.call({
            method: 'post',
            url,
            data,
        });
    }

    async patch(url, data) {
        return await this.call({
            method: 'patch',
            url,
            data,
        });
    }

    async delete(url) {
        return await this.call({
            method: 'delete',
            url,
        });
    }

    async postFile(url, file) {
        return await this.postForm(url, {file});
    }

    async postForm(url, params) {
        const formData = new FormData();
        for (const [key, value] of Object.entries(params))
            formData.append(key, value);
        const config = {
            headers: {'Content-Type': 'multipart/form-data'}
        };

        return await this.call({
            method: 'post',
            data: formData,
            url,
            ...config
        })
    }

    async downloadFile(path) {
        const res = await this.get(path);
        if (res.ok) {
            const data = res.result.data;
            const content = window.atob(data.content);
            download(content, data.name, data.mime);
        }
    }

    forceDownload(data) {
        const el = document.createElement('a')
        el.href = 'data:application/octet-stream;base64,' + data.content
        el.download = data.name;
        el.click();
    }
}

export default {
    install(Vue) {
        Vue.mixin({
            computed: {
                ...mapState(['user']),
                $http() {
                    return new Http(this.user && this.user.token);
                }
            }
        });
    }
};