import Vue from 'vue'
import VueRouter from 'vue-router'
import Dashboard from '../views/Dashboard.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/dashboard',
        name: 'dashboard',
        component: Dashboard
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/Login.vue')
    },
    {
        path: '/utenti',
        name: 'utenti',
        component: () => import('../views/Utenti.vue')
    },
    {
        path: '/utentipa',
        name: 'utentipa',
        component: () => import('../views/Utentipa.vue')
    },
    {
        path: '/gruppi',
        name: 'gruppi',
        component: () => import('../views/Gruppi.vue')
    },
    {
        path: '/regioni',
        name: 'regioni',
        component: () => import('../views/Regioni.vue')
    },
    {
        path: '/convenzioni',
        name: 'convenzioni',
        component: () => import('../views/audit/Convenzione.vue')
    },
    {
        path: '/convenzione/:id/allegati',
        name: 'convenzione_allegati',
        component: () => import('../views/audit/ConvenzioneAllegati.vue')
    },
    {
        path: '/report_periodica',
        name: 'reportistica_periodica',
        component: () => import('../views/audit/ReportPeriodica.vue')
    },
    {
        path: '/ordinativo/:id/allegati',
        name: 'ordinativo_allegati',
        component: () => import('../views/audit/OrdinativoAllegati.vue')
    },
    {
        path: '/ordinativo/:id/fatture',
        name: 'ordinativo_fatture',
        component: () => import('../views/audit/OrdinativoFatture.vue')
    },
    {
        path: '/convenzione/:id/cruscotto',
        name: 'cruscotto',
        component: () => import('../views/audit/Cruscotto.vue')
    },
    {
        path: '/convenzione/:id/:tipo',
        name: 'ordinativi',
        component: () => import('../views/audit/Ordinativo.vue')
    },

    {
        path: '/checklist',
        name: 'checklist',
        component: () => import('../views/checklist/CheckList.vue')
    },
    {
        path: '/categorie',
        name: 'categorie',
        component: () => import('../views/checklist/Categoria.vue')
    },
    {
        path: '/livelli',
        name: 'livelli',
        component: () => import('../views/checklist/Livello.vue')
    },
    {
        path: '/requisiti',
        name: 'requisiti',
        component: () => import('../views/checklist/Requisito.vue')
    },
    {
        path: '/compilazione',
        name: 'compilazione_lista',
        component: () => import('../views/compilazione/Lista.vue')
    },
    {
        path: '/compilazione/:id',
        name: 'compilazione',
        component: () => import('../views/compilazione/Compilazione.vue')
    },
    {
        path: '/ordinativo/:id/checklist',
        name: 'ordinativo_checklist',
        component: () => import('../views/checklist/Dettaglio.vue')
    },

    {
        path: '/fattura',
        name: 'fatture',
        component: () => import('../views/fattura/Fattura.vue')
    },
    {
        path: '/sal',
        name: 'sal',
        component: () => import('../views/stats/Sal.vue')
    },
]

const router = new VueRouter({
    routes
})

export default router
