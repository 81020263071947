<template>
    <div>
        <div class="mb-3">
            <v-btn :key="item.value" class="mr-3" v-for="item in items" @click="show(item.value)">{{
                    item.text
                }}
            </v-btn>
        </div>
        <v-card v-if="view">
            <v-card-text>
                <component :is="view"></component>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import OrdinativiAffidati from "@/views/stats/OrdinativiAffidati";
import OrdinativiValidati from "@/views/stats/OrdinativiValidati";
import AvanzamentoGlobale from "@/views/stats/AvanzamentoGlobale";
import NonConformita from "@/views/stats/NonConformita";

export default {
    name: 'Home',
    data: () => ({
        view: null,
        items: [
            {text: 'Verifiche Ispettive Affidate', value: 'ordinativi_affidati'},
            {text: 'Verifiche Ispettive Completate', value: 'ordinativi_validati'},
            {text: 'Avanzamento Globale', value: 'avanzamento_globale'},
            {text: 'Non Conformità Rilevate', value: 'non_conformita'},
        ]
    }),
    methods: {
        show(name) {
            switch (name) {
                case 'ordinativi_affidati':
                    this.view = OrdinativiAffidati;
                    break;
                case 'ordinativi_validati':
                    this.view = OrdinativiValidati;
                    break;
                case 'avanzamento_globale':
                    this.view = AvanzamentoGlobale;
                    break;
                case 'non_conformita':
                    this.view = NonConformita;
                    break
            }
        }
    }
}
</script>
