import Vue from 'vue'
import VueApexCharts from 'vue-apexcharts';
import App from '@/App.vue'
import router from '@/plugins/router'
import vuetify from '@/plugins/vuetify';
import store from '@/plugins/store';
import http from "@/plugins/http";
import notify from "@/plugins/notify";
import perms from "@/plugins/perms";

Vue.config.productionTip = false;
Vue.use(http);
Vue.use(notify);
Vue.use(perms);
Vue.use(VueApexCharts);

Vue.component('apexchart', VueApexCharts);

new Vue({
    router,
    vuetify,
    store,
    render: h => h(App)
}).$mount('#app')
