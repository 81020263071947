<template>
    <div>
        <v-row>
            <v-col>
                <DatePicker v-model="data_da" label="Data Affidamento Iniziale"/>
            </v-col>
            <v-col>
                <DatePicker v-model="data_a" label="Data Affidamento Finale"/>
            </v-col>
            <v-col>
                <v-btn @click.prevent="refresh" color="primary" :disabled="disable">
                    <v-icon>mdi-refresh</v-icon>
                </v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <apexchart height="500" :options="options_line" :series="values_line" type="bar"></apexchart>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <apexchart height="500" :options="options_pie" :series="values_pie" type="pie"></apexchart>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import DatePicker from "@/components/DatePicker";

export default {
    components: {DatePicker},
    data: () => ({
        labels_line: [],
        values_line: [],
        values_pie: [],
        labels_pie: [],
        data_da: null,
        data_a: null,
        controlli: null
    }),
    computed: {
        options_line() {
            return {
                legend: {
                    height: 150,
                    offsetY: 50,
                },
                colors: ['#008000', '#0077FF', '#ff8c00', '#ff0000',],
                labels: this.labels_line,
            };
        },
        options_pie() {
            return {
                legend: {
                    height: 150,
                    offsetY: 50,
                },
                labels: this.labels_pie,
                enabled: true,
                colors: ['#008000', '#0077FF', '#ff8c00', '#ff0000',],
                dataLabels: {
                    formatter: function (val, opts) {
                        return opts.w.config.series[opts.seriesIndex]
                    },
                },
                toolbar: {
                    show: true,
                    tools: {
                        download: true,
                        selection: true,
                    }
                }
            };
        },

        disable() {
            return !this.data_da || !this.data_a;
        }
    },
    methods: {
        async refresh() {
            const params = {data_a: this.data_a, data_da: this.data_da};
            const res = await this.$http.get('/audit/statistiche/non_conformita_line', params);
            if (res.ok) {
                this.labels_line = res.result.data.labels;
                this.values_line = res.result.data.values;
            }
            const res2 = await this.$http.get('/audit/statistiche/non_conformita_pie', params);
            if (res2.ok) {
                this.labels_pie = res2.result.data.labels;
                this.values_pie = res2.result.data.values;
                this.controlli = res2.result.data.values[0];
            }
        }
    }
}

</script>