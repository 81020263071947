import VuexPersistence from 'vuex-persist'
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
    storage: window.localStorage
})

export default new Vuex.Store({
    state: {
        user: null,
        dataset: {},
    },
    mutations: {
        setUser(state, user) {
            state.user = user;
        },
        logout(state) {
            state.user = null;

        },
        setDataset(state, item) {
            state.dataset[item.key] = item.value;
        }

    },
    plugins: [vuexLocal.plugin]
});