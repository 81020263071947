<template>
    <div>
        <v-row>
            <v-col>
                <DatePicker v-model="data_da" label="Data Affidamento Iniziale"/>
            </v-col>
            <v-col>
                <DatePicker v-model="data_a" label="Data Affidamento Finale"/>
            </v-col>
            <v-col>
                <v-btn @click.prevent="refresh" color="primary" :disabled="disable">
                    <v-icon>mdi-refresh</v-icon>
                </v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <apexchart height="500" :options="options" :series="values" type="bar"></apexchart>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import DatePicker from "@/components/DatePicker";

export default {
    components: {DatePicker},
    data: () => ({
        labels: [],
        values: [],
        data_da: null,
        data_a: null,
    }),
    computed: {
        options() {
            return {
                legend: {
                    height: 150,
                    offsetY: 50,
                },
                labels: this.labels,
                colors: ['#5dade2', '#e74c3c', '#f4d03f', '#a569bd', '#45b39d', '#34495e']
            };
        },
        disable() {
            return !this.data_da || !this.data_a;
        }
    },
    methods: {
        async refresh() {
            const params = {data_a: this.data_a, data_da: this.data_da};
            const res = await this.$http.get('/audit/statistiche/avanzamento_globale', params);
            if (res.ok) {
                this.labels = res.result.data.labels;
                this.values = res.result.data.values;
            }
        }
    }
}

</script>