import {mapState} from "vuex";

export default {
    install(Vue) {
        Vue.mixin({
            computed: {
                ...mapState(['user']),
            },
            methods: {
                $auth(ruoli) {
                    if (!this.user) return false;
                    if (typeof ruoli == 'string')
                        ruoli = [ruoli];
                    return ruoli.includes(this.user.ruolo);
                }
            }
        });
    }
};