<template>
    <div>
        <v-list-item three-line>
            <v-list-item-content>
                <v-list-item-title class="title">
                    FoolProof ver. 2.0 &emsp;&emsp;
                </v-list-item-title>
                <v-list-item-subtitle class="text-center">
                    <img alt="imq" src="@/assets/loghi/imq.png" width="50"> &emsp;
                    <img alt="equalitate" src="@/assets/loghi/equalitate.png" width="80">
                </v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list dense nav>
            <template v-for="item in items">
                <v-divider class="my-3" v-if="item.title === '-'" :key="item.key"></v-divider>
                <v-list-item v-else :key="item.title" :to="{name: item.to}">
                    <v-list-item-icon>
                        <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </template>
            <v-divider class="my-3"></v-divider>
            <v-list-item @click="logout">
                <v-list-item-icon>
                    <v-icon>mdi-logout</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title>Logout</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </div>
</template>

<script>
import {mapMutations, mapState} from "vuex";

export default {
    data: () => ({
        allItems: [
            {title: 'Dashboard', to: 'dashboard', icon: 'mdi-chart-bar', ruoli: ['amministratore', 'consip']},
            {title: 'Utenti', to: 'utenti', icon: 'mdi-account', ruoli: ['amministratore']},
            {title: 'Utenti PA', to: 'utentipa', icon: 'mdi-account', ruoli: ['amministratore']},
            {title: 'Gruppi', to: 'gruppi', icon: 'mdi-account-group', ruoli: ['amministratore']},
            {title: 'Regioni', to: 'regioni', icon: 'mdi-map', ruoli: ['amministratore']},
            {
                title: 'Convenzioni',
                to: 'convenzioni',
                icon: 'mdi-handshake-outline',
                ruoli: ['amministratore', 'ispettore', 'consip', 'pianificatore', 'validatore']
            },
            {
                title: 'Reportistica Periodica',
                to: 'reportistica_periodica',
                icon: 'mdi-counter',
                ruoli: ['amministratore', 'consip']
            },

            {title: '-', key: 1, ruoli: ['amministratore']},
            {title: 'Livelli di conformità', to: 'livelli', icon: 'mdi-counter', ruoli: ['amministratore']},
            {title: 'Categorie', to: 'categorie', icon: 'mdi-bookmark-check-outline', ruoli: ['amministratore']},
            {title: 'Requisiti', to: 'requisiti', icon: 'mdi-crosshairs-question', ruoli: ['amministratore']},
            {title: 'Checklist', to: 'checklist', icon: 'mdi-text-box-check-outline', ruoli: ['amministratore']},
            {title: '-', key: 2, ruoli: ['amministratore']},
            {
                title: 'Compilazione',
                to: 'compilazione_lista',
                icon: 'mdi-check',
                ruoli: ['amministratore', 'ispettore']
            },
            {title: '-', key: 3, ruoli: ['amministratore', 'consip']},
            {title: 'Fatture', to: 'fatture', icon: 'mdi-receipt', ruoli: ['amministratore', 'consip']},
            {title: '-', key: 4, ruoli: ['amministratore', 'consip']},
            {title: 'SAL', to: 'sal', icon: 'mdi-chart-bar', ruoli: ['amministratore', 'consip']},
        ],
    }),
    computed: {
        ...mapState(['user']),
        items() {
            return this.allItems.filter(item => !item.ruoli || item.ruoli.includes(this.user.ruolo))
        }
    },
    watch: {
        user(value) {
            if (value == null)
                this.$route.push({name: 'login'});
        }
    },
    methods: {
        ...mapMutations(['logout']),
    }
}
</script>